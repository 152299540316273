import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages'
import APAppPrivacyPolicy from './pages/app_development/apapp/privacy_policy'
import APAppHome from './pages/app_development/apapp';
import APAppAbout from './pages/app_development/apapp/about';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/app-development/apapp/' element={<APAppHome />} />
        <Route path='/app-development/apapp/about' element={<APAppAbout />} />
        <Route path='/app-development/apapp/privacy' element={<APAppPrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;