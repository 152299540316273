import React from 'react';

function APAppHome() {

    return (
        <>
            <head>
                <meta charset='utf-8' />
                <meta name='viewport' content='width=device-width' />
                <title>Action Potentials App</title>
            </head>
            <body style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", padding: "1em" }}>
                <h1>Action Potentials App</h1>
                <h3><a href="/app-development/apapp/about">About</a></h3>
                <h3><a href="/app-development/apapp/privacy">Privacy Policy</a></h3>
            </body>
        </>
    );
}

export default APAppHome;