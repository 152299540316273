import React from 'react';

function Home() {

  return (
    <>
        <div>
            <h1>Welcome to beauforest.com</h1>
        </div>
    </>
  );
}

export default Home;