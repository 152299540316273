import React from 'react';

function APAppAbout() {

    return (
        <>
            <head>
                <meta charset='utf-8' />
                <meta name='viewport' content='width=device-width' />
                <title>About</title>
            </head>
            <body style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", padding: "1em" }}>
                <h1>About</h1>
                <p>In this app, you can see how action potentials work by watching the action potential as it happens and adjusting the parameters. You can add drugs such as lidocaine, tetrodontoxin, and TEA to observe the effects on the action potential. You have the option to see a single action potential with a preset current injection, or a continuous option where you can manually inject current to trigger an action potential.</p>
            </body>
        </>
    );
}

export default APAppAbout;